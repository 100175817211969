"use client";
import axios from "axios";
import Image from "next/image";
import { useForm } from "react-hook-form";
import useAuthStore from "@/stores/useAuthStore";
import { useRouter } from "next/navigation";
import { InfoIcon } from "@/components/ui/Icons";
import { useState } from "react";
import Link from "next/link";
import { updateAxiosToken } from "@/utils/axiosInstance";

export type Props = {
  searchParams: Record<string, string> | null | undefined;
};

export default function Login(props: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { login } = useAuthStore();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const router = useRouter();
  const redirect = props.searchParams?.redirect;

  const onSubmit = async (data: any) => {
    //console.log("Email: ", data.email);
    //console.log("Password: ", data.password);
    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/login`,
        {
          email: data.email,
          password: data.password,
        },
        {
          withCredentials: true,
        }
      );

      //console.log("Response: ", res.data);
      const { token } = res.data;
      updateAxiosToken(token);
      login(token);
      setLoading(false);
      setLoginSuccess(true);
      setTimeout(() => {
        redirect ? router.push(redirect) : router.push("/app");
      }, 500);
    } catch (error: string | any) {
      //console.log("Error: ", error.response);
      setLoading(false);
      if (error.response.data) {
        const { msg } = error.response.data;

        if (msg === "Password is incorrect") {
          return setError("La contraseña es incorrecta");
        }

        if (msg === "User not exists") {
          return setError("El usuario no existe");
        }
      }
      return setError("Error desconocido");
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen items-center py-12 gap-5 md:py-0 md:pb-12">
      <div className="flex justify-center items-center flex-col px-5 text-center gap-3">
        <div className="flex flex-col gap-3">
          <h3>INICIAR SESIÓN</h3>
          <h1 className="text-4xl font-bold">MALÚ PÉREZ ONLINE</h1>
          {loading && (
            <div className="bg-light-pink inline-flex items-center gap-3 py-2 px-5 rounded-md border border-bright-pink border-1">
              <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-2 border-t-bright-pink" />
              Iniciando Sesión...
            </div>
          )}

          {loginSuccess && (
            <div className="bg-light-pink inline-flex items-center gap-3 py-2 px-5 rounded-md border border-bright-pink border-1">
              <InfoIcon height="16px" fill="green" />
              Inicio de sesión exitoso
            </div>
          )}

          {error && (
            <div className="bg-light-pink inline-flex items-center gap-3 py-2 px-5 rounded-md border border-bright-pink border-1">
              <InfoIcon height="16px" fill="red" />
              {error}
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4 w-full w-max-[300px] mt-3"
          >
            <input
              {...register("email", {
                required: "El correo electrónico es requerido",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Correo electrónico inválido",
                },
              })}
              type="email"
              placeholder="CORREO ELECTRONICO"
              className="h-[50px] w-full rounded-[5px] bg-transparent border border-deep-purple p-5 focus:outline-none  focus:border-bright-pink placeholder:text-deep-purple"
            />
            {errors.email && (
              <p className="text-red-500 inline-flex items-center gap-3">
                <InfoIcon height="16px" fill="red" />
                {String(errors.email.message)}
              </p>
            )}
            <input
              {...register("password", {
                required: "La contraseña es requerida",
                minLength: {
                  value: 6,
                  message: "La contraseña debe tener al menos 6 caracteres",
                },
              })}
              type="password"
              placeholder="CONTRASEÑA"
              className="h-[50px] w-full rounded-[5px] bg-transparent border border-deep-purple p-5 focus:outline-none  focus:border-bright-pink placeholder:text-deep-purple"
            />
            {errors.password && (
              <p className="text-red-500 inline-flex items-center gap-3">
                <InfoIcon height="16px" fill="red" />
                {String(errors.password.message)}
              </p>
            )}
            <Link href="/forgot-password">
              <span className="text-deep-purple text-sm font-normal">
                ¿Olvidaste tu contraseña?
              </span>
            </Link>
            <button
              type="submit"
              className="border border-deep-purple text-deep-purple rounded-full py-2 hover:bg-deep-purple hover:text-white transition duration-300 w-full"
            >
              INICIAR SESIÓN
            </button>
          </form>
          <span className="text-deep-purple text-sm font-normal">
            ¿Aún no tienes cuenta?
          </span>
          <Link href="/register">
            <span className="text-deep-purple text-sm font-normal">
              Regístrate ahora
            </span>
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center md:h-screen">
        <Image
          src="https://maluapp.b-cdn.net/resources/woman-mat.jpg"
          width={800}
          height={800}
          alt="Imagen Login"
          priority={true}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "100%",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
